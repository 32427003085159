<template>
	<div class="container">

		<div style="margin-bottom: 1rem"><h2>Bild Editor</h2></div>

		<imgMetaEditor v-if="imgMetatDat.visible" :par="imgMetatDat"></imgMetaEditor>

		<imgImport  v-if="imgObject.visible" :par="imgObject"  />

		<div>
			<select  @change="getIndex"  v-model="selKat">
				<option value="home">Home</option>
				<option value="gartengestaltung-main">Gartengestaltung Hauptseite</option>
				<option value="gartengestaltung-beratung">Gartengestaltung Beratung und Planung</option>
				<option value="gartengestaltung-mauern-wege">Gartengestaltung Mauern und Wege</option>
				<option value="gartengestaltung-teiche">Gartengestaltung Teiche, Bachläufe, Brunnen</option>
				<option value="gartengestaltung-bepflanzung">Gartengestaltung Bepflanzung und Rasenanlage</option>
				<option value="gartengestaltung-teichpflege">Gartengestaltung Garten und Teichpflege</option>
			</select>
		</div>

		<div class="imgList">
			 <div  class="item" v-for="(img, i) in imgs">

			 	<div>
			 		<div class="showTitle">{{ img.title}}</div>
			 		<div><img :src="$store.state.server + '/' + img.path" width="150" height="auto" :title="img.title" @click="importImg(i)"> </div>
			 	</div>

			 	<div class="visCont">
			 		<div title="sichtbar" v-if="img.visible == 1"><svg class="iVis"  @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#visible"/></svg></div>
			 		<div title="nicht sichtbar" v-else ><svg class="iUvis" @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#unvisible"/></svg></div>
			 	</div>

			 	<div><button @click="changeImgMetaDat(i)">Meta Daten eingeben</button></div>


			 </div>
		</div>

<!-- 		<select @change="changeSelKat"  v-model="selKat">
			<option v-for="(k) in pkKats" :value="k.id" :key="k.id">{{ k.text }}</option>
		</select> -->

	</div>
</template>

<script>
	import  imgMetaEditor from "@/components/ImgMetaEditor.vue";
	import axios from 'axios';
	import  imgImport from "@/components/GenericImgImport.vue";
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'ImgEditList',
		components: {
			imgMetaEditor,
			imgImport,
		},
		mounted(){
			this.getIndex();

		}, // end mounted 
		data: 
		function(){
			return {
				// kats: {'gartengestaltung-main' : []},
				selKat: null,
				imgs: null,
				imgMetatDat: {visible: false},
				imgObject: {visible: false}
			}
		}, // end data
		methods: {

			importImg(key){

				let self = this;
				this.imgObject.data = self.imgs[key];
				this.imgObject.callback = function(p){

					if (p === 1) {

						self.changeImg();
						self.selKat = self.imgs[key].slug;

					}
					self.imgObject.visible = false;
				};

				this.imgObject.visible = true;

			},
			changeImg(){

				this.imgMetatDat.data = this.imgObject.data;

				let self = this;
				this.imgMetatDat.callback = function(p){

					if (p === 1) {

						self.saveNewImgWithMeataData();
					}
					self.imgMetatDat.visible = false;
				};

				this.imgMetatDat.visible = true;

			},
			saveNewImgWithMeataData(){

						let self = this;
						let url = this.$store.state.server + '/be/saveNewImgWithMeataData.php';

						axios.post(url, {
							crossDomain: true,
							headers: {
							},
							data: { 
								img: this.imgObject.newImg, 
								alt_text: this.imgMetatDat.data.alt_text, 
								title:  this.imgMetatDat.data.title,
								path: this.imgMetatDat.data.path,
								id: this.imgMetatDat.data.id,
								slug: this.imgMetatDat.data.slug,
							},
						})
						.then(function (resp) {


						let slug = resp.data.data[0];
							

						self.selKat = slug;
							// self.$router.push({ path: '/' + 'imgEditList' });

							self.$router.go();

							// console.log(resp.data.data);

							// if (resp.data.data[0]) {
							// 	o.title = resp.data.data[1];
							// 	o.alt_text = resp.data.data[2];
							// }

						});
			},
			changeImgMetaDat(key){

				this.imgMetatDat.data = this.imgs[key];


				let self = this;
				this.imgMetatDat.callback = function(p){



					if (p === 1) {

						self.saveImgMetaDat(self.imgMetatDat.data);


					}
					self.imgMetatDat.visible = false;
				};

				this.imgMetatDat.visible = true;
			},

			saveImgMetaDat(o){

				let self = this;
				let url = this.$store.state.server + '/be/changeImgMetaDat.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { o: o },
				})
				.then(function (resp) {

					if (resp.data.data[0]) {
						o.title = resp.data.data[1];
						o.alt_text = resp.data.data[2];
					}

				});
			},

			toggleItemVis(key){


				let self = this;
				let url = this.$store.state.server + '/be/toggleImgVis.php';


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { id: self.imgs[key].id, vis: self.imgs[key].visible },
				})
				.then(function (resp) {

					let id = resp.data.data[0];
					let vis = resp.data.data[1];

					self.imgs[key].visible = vis;
				});

			},


			getIndex (){

				if (this.selKat === null  ) {

					if (! sessionStorage.getItem('imgEdSlug')) {
						sessionStorage.setItem('imgEdSlug', 'home');
					}

					this.selKat = sessionStorage.getItem('imgEdSlug');
				} else {
					sessionStorage.setItem('imgEdSlug', this.selKat);
				}



				let self = this;
				let url = this.$store.state.server + '/be/getImagesIndex.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: this.selKat },
				})
				.then(function (resp) {


					self.imgs = resp.data.data[0];
					// self.pk = self.pkFilter = resp.data.data[1];

				});
			},


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.imgList {
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	
	& selcect {
		cursor: pointer;
	}
	& .item {
		display: flex;
		align-items: center;

		& > div {
			position: relative;
			margin-top: 1.5rem;
		}

		& .visCont svg {
			height: 1.6rem;
			width: 1.6rem;
			cursor: pointer;
		}

		& img, button {
			cursor: pointer;

		}

		& .showTitle {
		position: absolute;
		width: 60rem;
		top: -1.5rem;

		}
	}

	&  div {
		margin-right: .6rem;
		
	}
}

.iVis {
	stroke: #72b579;
}
.iUvis {
	stroke: black;
}



</style>
